import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import {
    SectionHeading,
    SmallerHeading,
    SectionContainer,
    ContentWrapper,
} from '~/styles/common';
import { LangContext } from '~/components/../providers/LangProvider';
import React, { useContext, useState } from 'react';
import { tabletBreakpoint, smallScreenBreakpoint1064px, mobileBreakpoint } from '~/styles/common';
import TransHTML from '~components/transHTML';

const contacts = {
    es: {
        name: 'Support',
        email: 'support@trisbee.com',
        image: '/static/images/new_web/employees/trisbee-support.svg',
    },
    it: {
        name: 'Support',
        email: 'support@trisbee.com',
        image: '/static/images/new_web/employees/trisbee-support.svg',
    },
    cz: {
        name: 'Podpora',
        email: 'podpora@trisbee.com',
        image: '/static/images/new_web/employees/trisbee-support.svg',
    },
    sk: {
        name: 'Podpora',
        email: 'support@trisbee.com',
        image: '/static/images/new_web/employees/trisbee-support.svg',
    },
};

const contactsPersonal = [
    {
        name: t`global.trisbee.support`,
        email: 'support@trisbee.com',
        image: '/static/images/new_web/support-logo.svg',
    }
]

const HaveAQuestion = ({ whiteBg, supportContact = false, sales = true }) => {
    const ctx = useContext(LangContext);
    let contactData = contacts[ctx.state.country];

    return (
        <HaveAQuestionSection supportContact={supportContact} whiteBg={whiteBg}>
            <ContentWrapper>
                <TopHeading>
                    <Trans id="global.homepage.have_a_question.heading" />
                </TopHeading>
                {sales && (
                    <SmallerHeading>
                        <Trans id="global.homepage.have_a_question.contact_us" />
                    </SmallerHeading>
                )}
                {supportContact ? <Profile>
                    {contactsPersonal[0].image && (
                        <Photo src={contactsPersonal[0].image} alt={contactsPersonal[0].name} />
                    )}
                    <Info>
                        <Name>
                            <TransHTML id={contactsPersonal[0].name}/>
                        </Name>
                        <Email href={`mailto:${contactsPersonal[0].email}`}>
                            {contactsPersonal[0].email}
                        </Email>
                    </Info>
                </Profile> :  <Profile>
                    {contactData.image && (
                        <Photo src={contactData.image} alt={contactData.name} />
                    )}

                    <Info>
                        <Name>{contactData.name}</Name>
                        <Email href={`mailto:${contactData.email}`}>
                            {contactData.email}
                        </Email>
                    </Info>
                </Profile>}
            </ContentWrapper>
        </HaveAQuestionSection>
    );
};

const HaveAQuestionSection = styled(SectionContainer)`
    background: ${props => (props.whiteBg ? 'white' : 'edf2f4')};
    padding: ${props => (props.supportContact ? '3rem 1rem 6rem 1rem' : '3rem 1rem')};
`;

const TopHeading = styled.h2`
    font-size: 2rem;
    text-align: center;
    font-weight: 700;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3rem;
        line-height: 1.2;
    }
`;

const Profile = styled.div`
    display: flex;
    margin-top: ${props => (props.supportContact ? '1rem' : '5rem')};

    @media (max-width: 446px) {
        justify-content: center;
        flex-wrap: wrap;
    }
`;

const Photo = styled.img`
    max-width: 170px;
    width: 100%;
    height: auto;
`;

const Info = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 2rem;

    @media (max-width: 446px) {
        margin-left: 0;
        margin-top: 1.5rem;
        text-align: center;
    }
`;

const Name = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.6px;
    color: #000000;
`;

const Email = styled.a`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.6px;
    text-decoration-line: underline;
    color: #0cc8a8;
    &:hover {
        color:#004a4a;
    }
`;

export default HaveAQuestion;
